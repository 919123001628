<script setup lang="ts">
import BackgroundImage from "~/components/shared/BackgroundImage.vue";

import type { NuxtError } from "#app";

const props = defineProps({
  error: Object as () => NuxtError,
});

const errorDetails = computed(() => {
  let obj = {};
  if (props.error.statusCode === 404) {
    obj = {
      type: 404,
      msg: "نأسف، يبدو أن الصفحة غير موجودة",
    };
  } else if (props.error.statusCode === 403) {
    obj = {
      type: 403,
      msg: "نأسف، أنت لا يمكنك الدخول إلى هذي الصفحة",
    };
  } else {
    obj = {
      type: 500,
      msg: "نأسف، يبدو أنه حدث خطأ ما",
    };
  }
  return obj;
});
</script>

<!-- <template>
  <div>
    <h1>{{ error.statusCode }}</h1>
    <NuxtLink to="/">Go back home</NuxtLink>
  </div>
</template> -->

<template>
  <div>
    <TheHeader :error-page="true" />
    <div class="page__section">
      <BackgroundImage color="#ffd895" />
      <div class="not-found">
        <v-row class="error-type">
          <v-col cols="12">
            <img
              class="error-num"
              :src="`images/error-page/Num-${errorDetails.type}.svg`"
              :alt="errorDetails.type"
            />
            <!-- <h1>{{ errorDetails.type }}</h1> -->
          </v-col>
          <v-col cols="12">
            <img
              class="error-img"
              :src="`images/error-page/Error-${errorDetails.type}.svg`"
              :alt="errorDetails.type"
            />
          </v-col>
          <v-col cols="12">
            <p>{{ errorDetails.msg }}</p>
          </v-col>
          <v-col cols="12">
            <nuxt-link to="/">
              <v-btn height="50" class="main-btn" rounded>
                العودة إلى الصفحة الرئيسية
                <img src="~/assets/images/error-page/arrow.svg" alt="back" />
              </v-btn>
            </nuxt-link>
          </v-col>
        </v-row>
      </div>
    </div>
    <TheFooter />
  </div>
</template>

<style lang="scss" scoped>
.not-found {
  margin-bottom: 150px;
  margin-top: 80px;
  //   height: 29vh;
  .error-type {
    display: grid;
    place-items: center;
    text-align: center;
    h1 {
      color: #464688;
      font-size: 140px;
      font-family: cursive;
    }

    p {
      font-family: "AJannatLT";
      font-size: 33px;
      font-weight: bold;
      line-height: 1.85;
      text-align: center;
      color: #37474f;
    }
  }
}
.main-btn {
  background-color: #464688 !important;
  font-size: 14px !important;
  font-weight: bold;
  img {
    width: 28px !important;
  }
}

@media (max-width: 991px) {
  .not-found {
    margin-bottom: 100px;
    .error-type {
      h1 {
        font-size: 100px;
      }
      .error-num {
        width: 40%;
      }
      .error-img {
        width: 80%;
      }
      p {
        font-size: 18px;
      }
    }
  }
  .main-btn {
    font-size: 13px !important;
    img {
      width: 22px !important;
    }
  }
}
</style>
