<template>
  <div>
    <!-- ############################## Hero Section ########################################### -->
    <v-row v-if="$route.path === '/'" class="hero">
      <v-col cols="12" sm="5" class="hero_img">
        <img src="~/assets/images/header/hero-png.png" alt="hero img" />
      </v-col>
      <v-col cols="12" sm="7">
        <div class="hero__text">
          <h4>أهلا بك في الموقع الرسمي للدكتورة سمیة الناصر</h4>
          <h1>{{ description || header.website_description }}</h1>
          <div class="hero__btns">
            <span>
              <nuxt-link to="/pages/about">
                <v-btn width="222" class="main-btn" rounded>
                  تعرف أكثر على الدكتورة
                  <img src="~/assets/images/header/more.svg" />
                </v-btn>
              </nuxt-link>
            </span>
            <a href="/#courses">
              <v-btn width="222" class="second-btn" rounded>
                <span> تصفح المواد </span>
                <img style="margin-right: 90px" src="~/assets/images/header/mored.svg" />
              </v-btn>
            </a>
          </div>
        </div>
      </v-col>
      <v-col v-if="bannerImage" cols="12" class="banner-col">
        <img :src="bannerImage" :class="lgAndUp ? 'large-banner' : 'small-banner'" />
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useHomeStore } from '~/stores/HomeStore';
import { useDisplay } from "vuetify";

const homeStore = useHomeStore();
const route = useRoute();

const description = ref("20 عاماً من الإبداع والخبرة في مجال الوعي وتطوير الذات");
const snapchat = ref("");
const instagram = ref("");
const twitter = ref("");
const tiktok = ref("");
const youtube = ref("");
const podcast = ref("");

const home = computed(() => homeStore.home);

const bannerImage = computed(() => {
  if (homeStore.header && homeStore.header.header) {
    return homeStore.header.header.banner;
  } else {
    return null;
  }
});

const header = computed(() => {
  return home.value !== undefined ? home.value.header : {};
});

const { smAndUp, lgAndUp } = useDisplay();

onMounted(() => {
  if (route.path === "/" && header.value) {
    snapchat.value = header.value.sumaya_snapchat;
    instagram.value = header.value.sumaya_instagram;
    twitter.value = header.value.sumaya_twitter;
    tiktok.value = header.value.sumaya_tiktok;
    youtube.value = header.value.sumaya_youtube;
    podcast.value = header.value.sumaya_podcast;
  }
});
</script>

<style lang="scss" scoped>
.v-row {
    margin: 0px !important;
}
/* Hero Style */
.hero {
  direction: ltr;
  margin-top: 0px;
  margin-bottom: 100px;

  .hero_img {
    padding: 0 !important;

    img {
      width: 45vw;
    }
  }

  .hero__text {
    display: grid;
    place-items: center;
    margin-top: 10vw;
    padding: 0 15% 0 20%;
    direction: rtl;
    text-align: center;

    h4 {
      font-size: 22px;
      font-size: clamp(15px, 2vw, 30px);
      font-weight: normal;
    }

    h1 {
      font-size: clamp(37px, 5vw, 55px);
      font-weight: bold;
      line-height: 80px;
    }
  }

  .hero__btns {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;

    button {
      margin: 10px 5px;
    }
  }

  .second-btn span {
    text-align: center;
  }

  .social-icons {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;

    span {
      display: flex;
      flex-flow: wrap;

      a {
        display: flex;
        justify-content: center;
      }

      img {
        margin: 0px 10px;
      }
    }
  }
}

.banner-col {
  padding: 12px 0 !important;
}

.small-banner {
  width: 100%;
  height: 30vh;
}

.large-banner {
  width: 100%;
  height: 39vh;
}

@media (max-width: 1600px) {
  .hero {
    .hero__text {
      margin-top: 8vw;
      padding: 0 8% 0 8%;
    }
  }
}

@media (max-width: 991px) {
  .hero {
    .hero__text {
      padding: 0 10% 0 8%;
    }

    .hero_img {
      padding: 0 !important;

      img {
        width: 47vw;
      }
    }

    .hero__btns {
      margin-top: 5px;
      justify-content: center;

      button {
        margin-top: 10px;
      }

      .social-icons {
        justify-content: center;
      }
    }
  }

  .small-banner {
    height: 45vh;
  }
}

@media (max-width: 768px) {
  .hero {
    .hero__text {
      padding: 0 5% 0 7%;

      h1 {
        font-size: 32px !important;
      }
    }
  }
}

@media (max-width: 600px) {
  .hero {
    margin-bottom: 50px;

    .hero_img {
      padding: 0 !important;

      img {
        width: 100vw;
      }
    }

    .hero__text {
      margin-top: 0px;
      padding: 0 4%;

      h4 {
        font-size: 15px;
      }

      h1 {
        font-size: 22px !important;
        line-height: 50px;
      }
    }
  }

  .row {
    margin: 0px !important;
  }

  .small-banner {
    height: 25vh;
  }
}
</style>
